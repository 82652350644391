// Bootstrap
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
// Variables
@import "app/common/variables";

.spendmanager {
	&.logo1 {
		color: white;
	}
	&.logo2 {
		color: #00C;
	}
}

// support for QBE input fields in datatable header
.sm-qbe-datatable {
	thead input {
		width: 100%;
		padding: 2px;
		box-sizing: border-box;
	}

	.waiting-for-data-row {
		height: 50px;
		td {
			padding: 0;
			margin: 0;
		}
	}

	border-collapse: collapse;

	tbody > tr:hover {
		background-color: rgba(theme-color("primary"), .1);
	}

	tbody > tr.active {
		background-color: rgba(theme-color("primary"), .2);
	}

	tbody > tr.current {
		background-color: rgba(theme-color("primary"), .2);
	}

	tbody > tr.inactive {
		color: rgba(theme-color("danger"), .75);
	}

	tbody > tr > td.child input {
		display: none;
	}
	
	tr.child .sm-percent-bar {
		display: inline-block;
		min-width: 125px;
	}

	button.btn {
		margin: 0.125rem;
	}
}

.sm-horizontal-form {
	max-width: 750px;
	label, .label-column {
		text-align: left;
	}

    button[type=submit] {
    	width: 150px;
    }

    em {
    	margin-right: 0.5rem;
    }
}

@media (min-width: 992px) {
	.sm-horizontal-form {
		label, .label-column {
			text-align: right;
		}
	}
}

.sm-invoice {
	.card {
		margin: 0;

		.card-body {
			padding-top: 0;
		}
	}

	.sm-invoice-header {
		color: #888;
		font-weight: bold;
		padding-bottom: 0.25rem;
	}

	.table thead tr th, .table tbody tr td {
		padding: 0.25rem;

		&.currency {
			text-align: right;
		}
	}

	.backorder-date {
		padding-left: 0.5rem;
	}

	.sm-additional-fields {
		label {
			text-align: left;
		}
	}


	@media (min-width: 576px) {
		.sm-additional-fields {
			label {
				text-align: right;
			}
		}
	}

}

.sm-invoice-table {

	tr[role=row] .sm-first-item-cell {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width:300px;

		&::before {
		  content: '';
		  display: block;
		}

	}

	tr.child .sm-first-item-cell {
		display: inline-block;

	}
}

.highlight {
	color: theme-color("primary")
}

.devbar {
	padding: 0.5rem;
	position: relative;
	z-index: 2000;
	color: #ccc;
	background-color: #122;

	pre {
		color: #ccc;
	}

	label {
		margin: 0;
	}

	.devbar-item {
		padding: 0 1rem;
	}

	.devbar-dump-container {
		display: inline-block;
		vertical-align: middle;
		color: black;
		background-color: white;
	}
}

.sm-dashboard-chart {
	margin-bottom: 1rem;
}

.sm-choose-store, .sm-choose-master-account {
	font-size: 16pt;
	margin-top: -3px;
	color: #777;
}

.sm-dashboard-chart, .sm-chart {
	position: relative;

	.spinner {
		position: absolute;
		left:50%;
		top: 50%;
	}
}

.trending-up {
	color: green;
}

.trending-down {
	color: red;
}

svg.sm-d3-chart {
	.axis {
		font-size: 18pt;
	}
}

.sm-dashboard-chart {
	svg.sm-d3-chart {
		.axis {
			font-size: 22pt;
			color: #444;

			text {
				text-decoration: none !important;
			}
		}
	}
}


.tooltip.sm-d3-tooltip, {
	position: absolute;
	padding: 7px;
	font-size: 0.9em;
	pointer-events: none;
	border-radius: 4px;
	background-color: black;
	color: white;

	-moz-box-shadow:    3px 3px 10px 0px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25);
	box-shadow:         3px 3px 10px 0px rgba(0, 0, 0, 0.25);


}

.sm-chartjs-tooltip {
	background-color: rgba(0,0,0,0.7);
	color: white;
	position: absolute;
	z-index: 1000;

	td,th {
		padding: 2px;
	}
}

/* show the clear/cancel button for search input */
input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}

/* removes the space taken up by the clear/cancel button when placeholder is shown (there's no button but the space cuts off the placeholer) */
input:placeholder-shown::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

input[type=search] {
	min-width: 4rem;
}

.sm-qbe-autocomplete-dropdown {
	position: absolute;

	.dropdown-toggle {
		width: 0px;
		padding-top: 1px;
		padding-bottom: 9px;
	}

	.dropdown-menu {
		max-height: 140px;
		overflow: auto;
	}

	@media (min-width: 992px) {
		.dropdown-menu {
			max-height: 280px;
		}
	}
}

.sm-dashboard-card {
	a:hover {
		text-decoration: none;
	}
}

.sm-monthrangepicker {
	width: 100%;
	max-width: 16rem;
}

.sm-help-card {
	border: 1px solid rgba(0, 0, 0, 0.125);

	.card-header >a[data-toggle] {
        color: #aaa;
	}
}

.sm-advanced-container {
	a[data-toggle] {
        color: #aaa;
	}
}

.sm-subtle-error-card.card {
	border: none;
	box-shadow: none;
}

.sm-master-account-list-item {
	white-space: nowrap;
}

.btn-outline-primary.disabled.active {
	background-color: #ccddff
}

.sm-export-options-form {
	.sm-header {
		padding: 0.5rem;
		background-color: #eee;
		border-radius: 4px;
	}

	.sm-item {
		padding: 0 0.5rem;
	}
}

.dataTables_wrapper .dt-buttons {
	label {
		margin-top: auto;
		margin-bottom: auto;
	}
}

.sm-pdfreport {

	border: 0.5mm solid black;

	.toggleopt {
		display: none;
	}
	.toggleopt.active {
		display: inline;
	}
}

.sm-display-webreportenabled {
	margin-left: 0.25rem;
	font-weight: bold;
}

.sm-display-webreportdisabled {
	margin-left: 0.25rem;
	font-weight: lighter;
}
